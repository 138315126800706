* {
  margin: 0;
  padding: 0;
}

html,body {
  height:100vh;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}

#root{
  height: 100%;
}
